export const createDecision = /* GraphQL */ `
    mutation createDecision(
        $workspace_id: Int!
        $name: String!
        $json: String
        $description: String
    ) {
        createDecision(
            input: {
                workspace_id: $workspace_id
                name: $name
                json: $json
                description: $description
            }
        ) {
            workspace_id
            decisions {
                id
                name
                json
                workspace_id
                description
                owner
                created
                updated
            }
            mutation
        }
    }
`;

export const createWorkspace = /* GraphQL */ `
    mutation createWorkspace(
        $name: String!
        $json: String
        $description: String
    ) {
        createWorkspace(
            input: { name: $name, json: $json, description: $description }
        ) {
            company_id
            workspaces {
                id
                name
                json
                owner
                created
                updated
                description
            }
            mutation
        }
    }
`;

export const createPermission = /* GraphQL */ `
    mutation createPermission($input: PermissionCreateInput!) {
        createPermission(input: $input) {
            workspace_id
            user_id
            read
            write
            admin
            owner_only
            fc_r
            fc_w
            vp_r
            vp_w
            vp_c
            fi_r
            fi_w
            ch_r
            ch_w
            ch_c
            va_r
            va_w
            sc_r
            sc_w
        }
    }
`;

export const updatePermission = /* GraphQL */ `
    mutation updatePermission(
        $id: PermissionIdInput!
        $input: PermissionUpdateInput!
    ) {
        updatePermission(id: $id, input: $input) {
            workspace_id
            user_id
            read
            write
            admin
            owner_only
            fc_r
            fc_w
            vp_r
            vp_w
            vp_c
            fi_r
            fi_w
            ch_r
            ch_w
            ch_c
            va_r
            va_w
            sc_r
            sc_w
        }
    }
`;

export const deletePermission = /* GraphQL */ `
    mutation deletePermission($id: PermissionIdInput!) {
        deletePermission(id: $id) {
            workspace_id
            user_id
            read
            write
            admin
            owner_only
            fc_r
            fc_w
            vp_r
            vp_w
            vp_c
            fi_r
            fi_w
            ch_r
            ch_w
            ch_c
            va_r
            va_w
            sc_r
            sc_w
        }
    }
`;

export const inviteUser = /* GraphQL */ `
    mutation inviteUser($input: InviteUserInput!) {
        inviteUser(input: $input) {
            email
            id
        }
    }
`;

export const inviteUserL = /* GraphQL */ `
    mutation inviteUserL(
        $users: [UserInviteInput!]!
        $permission: PermissionInviteInput!
        $url: String!
    ) {
        inviteUserL(users: $users, permission: $permission, url: $url)
    }
`;

export const redeemPermission = /* GraphQL */ `
    mutation redeemPermission($code: String!) {
        redeemPermission(code: $code)
    }
`;

export const updateWorkspace = /* GraphQL */ `
    mutation updateWorkspace(
        $id: Int!
        $name: String!
        $json: String
        $description: String
    ) {
        updateWorkspace(
            workspace_id: $id
            input: { name: $name, json: $json, description: $description }
        ) {
            company_id
            workspaces {
                id
                name
                json
                description
                owner
                created
                updated
            }
            mutation
        }
    }
`;

export const updateDecision = /* GraphQL */ `
    mutation updateDecision(
        $id: Int!
        $name: String!
        $json: String
        $description: String
    ) {
        updateDecision(
            decision_id: $id
            input: { name: $name, json: $json, description: $description }
        ) {
            workspace_id
            decisions {
                id
                name
                workspace_id
                json
                description
                owner
                created
                updated
            }
            mutation
        }
    }
`;

export const deleteDecision = /* GraphQL */ `
    mutation deleteDecision($id: Int!) {
        deleteDecision(decision_id: $id) {
            workspace_id
            decisions {
                id
                name
                json
                workspace_id
            }
            mutation
        }
    }
`;

export const deleteWorkspace = /* GraphQL */ `
    mutation deleteWorkspace($id: Int!) {
        deleteWorkspace(workspace_id: $id) {
            company_id
            workspaces {
                id
                name
                json
                # mutation
            }
            mutation
        }
    }
`;

export const createFactorL = /* GraphQL */ `
    mutation createFactorL($input: [FactorCreateInputL!]) {
        createFactorL(input: $input) {
            decision_id
            mutation
            factors {
                id
                decision_id
                parent_id
                # reference_id
                description
                owner
                name
                json
                order_str
                is_group
                is_table
                is_column
                # is_row
                # is_bp
                format
                suffix
                value_type
                unit_id
                enum_id
                m_score
                index_rule_id
                m_index
                set_id
                weight
                score_rule_id
                index_rule_id
                set_id
                min
                max
            }
            enums {
                id
                decision_id
                name
                values {
                    id
                    enum_id
                    value
                }
            }
            enum_values {
                id
                enum_id
                value
            }
            viewpoint_mappings {
                viewpoint_id
                factor_id
                score_rule_id
                index_rule_id
                set_id
                json
                order_str
                unit_id
                weight
                use_m_score
                use_m_index
            }
        }
    }
`;

export const updateFactorL = /* GraphQL */ `
    mutation updateFactorL(
        $factor_id: Int!
        $name: String
        $description: String
        $order_str: String
        $value_type: String
        $parent_id: Int
        $json: String
        $format: String
        $m_score: Float
        $m_index: Int
        $score_rule_id: Int
        $index_rule_id: Int
        $set_id: Int
        $weight: Float
        $unit_id: Int
        $min: Float
        $max: Float
    ) {
        updateFactorL(
            input: {
                id: $factor_id
                name: $name
                description: $description
                order_str: $order_str
                value_type: $value_type
                parent_id: $parent_id
                json: $json
                format: $format
                m_index: $m_index
                m_score: $m_score
                score_rule_id: $score_rule_id
                index_rule_id: $index_rule_id
                set_id: $set_id
                weight: $weight
                unit_id: $unit_id
                min: $min
                max: $max
            }
        ) {
            decision_id
            mutation
            factors {
                id
                decision_id
                parent_id
                # reference_id
                owner
                name
                description
                json
                order_str
                is_group
                is_table
                is_column
                # is_row
                # is_bp
                format
                suffix
                value_type
                enum_id
                m_score
                m_index
                weight
                score_rule_id
                index_rule_id
                set_id
                unit_id
                min
                max
            }
        }
    }
`;

export const createGroupL = /* GraphQL */ `
    mutation createGroupL($input: [GroupCreateInputL!]) {
        createGroupL(input: $input) {
            decision_id
            mutation
            factors {
                id
                decision_id
                parent_id
                owner
                name
                description
                json
                order_str
                is_group
                is_table
                is_column
                format
                suffix
                value_type
                m_score
                m_index
                score_rule_id
                index_rule_id
                set_id
                weight
                min
                max
            }
        }
    }
`;

export const updateGroupL = /* GraphQL */ `
    mutation updateGroupL(
        $factor_id: Int!
        $name: String
        $description: String
        $order_str: String
        $parent_id: Int
        $json: String
        $m_score: Float
        $m_index: Int
        $score_rule_id: Int
        $index_rule_id: Int
        $set_id: Int
        $weight: Float
    ) {
        updateGroupL(
            input: {
                id: $factor_id
                name: $name
                description: $description
                order_str: $order_str
                json: $json
                parent_id: $parent_id
                m_index: $m_index
                m_score: $m_score
                score_rule_id: $score_rule_id
                index_rule_id: $index_rule_id
                set_id: $set_id
                weight: $weight
            }
        ) {
            decision_id
            mutation
            factors {
                id
                decision_id
                parent_id
                # reference_id
                owner
                name
                description
                json
                order_str
                is_group
                is_table
                is_column
                # is_row
                # is_bp
                format
                suffix
                value_type
                m_score
                m_index
                score_rule_id
                index_rule_id
                set_id
                weight
                min
                max
            }
        }
    }
`;

export const createTableL = /* GraphQL */ `
    mutation createTableL($input: [TableCreateInputL!]) {
        createTableL(input: $input) {
            decision_id
            mutation
            factors {
                id
                decision_id
                parent_id
                owner
                name
                description
                json
                order_str
                is_group
                is_table
                is_column
                format
                suffix
                value_type
                m_score
                m_index
                score_rule_id
                index_rule_id
                set_id
                weight
            }
        }
    }
`;

export const updateTableL = /* GraphQL */ `
    mutation updateTableL(
        $factor_id: Int!
        $name: String
        $description: String
        $order_str: String
        $json: String
        $m_score: Float
        $m_index: Int
        $score_rule_id: Int
        $index_rule_id: Int
        $set_id: Int
        $weight: Float
    ) {
        updateTableL(
            input: {
                id: $factor_id
                name: $name
                description: $description
                order_str: $order_str
                json: $json
                m_score: $m_score
                m_index: $m_index
                score_rule_id: $score_rule_id
                index_rule_id: $index_rule_id
                set_id: $set_id
                weight: $weight
            }
        ) {
            decision_id
            mutation
            factors {
                id
                decision_id
                parent_id
                # reference_id
                owner
                name
                description
                json
                order_str
                is_group
                is_table
                is_column
                # is_row
                # is_bp
                format
                suffix
                value_type
                m_score
                m_index
                score_rule_id
                index_rule_id
                set_id
                weight
                min
                max
            }
        }
    }
`;

export const createColumnL = /* GraphQL */ `
    mutation createColumnL($input: [ColumnCreateInputL!]) {
        createColumnL(input: $input) {
            mutation
            decision_id
            factors {
                id
                decision_id
                parent_id
                owner
                name
                json
                order_str
                is_group
                is_table
                is_column
                format
                suffix
                value_type
                unit_id
                m_score
                m_index
                score_rule_id
                index_rule_id
                set_id
                weight
                min
                max
            }
        }
    }
`;

export const updateColumnL = /* GraphQL */ `
    mutation updateColumnL(
        $factor_id: Int!
        $name: String!
        $description: String
        $order_str: String!
        $json: String
        $m_score: Float
        $m_index: Int
        $score_rule_id: Int!
        $index_rule_id: Int!
        $set_id: Int
        $weight: Float!
    ) {
        updateColumnL(
            input: {
                id: $factor_id
                name: $name
                description: $description
                order_str: $order_str
                json: $json
                m_score: $m_score
                m_index: $m_index
                score_rule_id: $score_rule_id
                index_rule_id: $index_rule_id
                set_id: $set_id
                weight: $weight
            }
        ) {
            mutation
            decision_id
            # parent_id
            # owner
            # is_column
            # is_row
            factors {
                id
                decision_id
                parent_id
                # reference_id
                owner
                name
                description
                json
                order_str
                is_group
                is_table
                is_column
                # is_row
                # is_bp
                format
                suffix
                value_type
                enum_id
                m_score
                m_index
                score_rule_id
                index_rule_id
                set_id
                weight
                min
                max
            }
        }
    }
`;

export const moveRowL = /* GraphQl */ `
    mutation moveRowL($table_id: Int!, $old_row_id: String!, $new_row_id: String!){
        moveRowL(table_id: $table_id, old_row_id: $old_row_id, new_row_id: $new_row_id) {
            decision_id
            mutation
            old_row_id
            factors {
                id
                decision_id
                parent_id
                # reference_id
                owner
                name
                description
                json
                order_str
                is_group
                is_table
                is_column
                # is_row
                # is_bp
                format
                suffix
                value_type
                m_score
                m_index
                score_rule_id
                index_rule_id
                set_id
                weight
                min
                max
            }
            values {
                choice_id
                factor_id
                row_id
                value
                enum_value_id
                json
            }
        }
    }

`;

export const deleteFactorL = /* GraphQL */ `
    mutation deleteFactorL($id: [Int!]) {
        deleteFactorL(ids: $id) {
            decision_id
            mutation
            factors {
                id
                decision_id
                parent_id
                # reference_id
                owner
                name
                json
                order_str
                is_group
                is_table
                is_column
                # is_row
                # is_bp
                format
                suffix
                value_type
                enum_id
                m_score
                m_index
                weight
                score_rule_id
                index_rule_id
                set_id
                min
                max
            }
            viewpoint_mappings {
                viewpoint_id
                factor_id
                score_rule_id
                index_rule_id
                set_id
                json
                order_str
                unit_id
                weight
                use_m_score
                use_m_index
            }
        }
    }
`;

export const deleteGroupL = /* GraphQL */ `
    mutation deleteGroupL($id: [Int!]) {
        deleteGroupL(ids: $id) {
            decision_id
            mutation
            factors {
                id
                decision_id
                parent_id
                # reference_id
                owner
                name
                json
                order_str
                is_group
                is_table
                is_column
                # is_row
                # is_bp
                format
                suffix
                value_type
                enum_id
                m_score
                m_index
                weight
                score_rule_id
                index_rule_id
                set_id
                min
                max
            }
        }
    }
`;

export const deleteTableL = /* GraphQL */ `
    mutation deleteTableL($id: [Int!]) {
        deleteTableL(ids: $id) {
            factors {
                id
                decision_id
                parent_id
                # reference_id
                owner
                name
                description
                json
                order_str
                is_group
                is_table
                is_column
                # is_row
                # is_bp
                format
                suffix
                value_type
                enum_id
                m_score
                m_index
                score_rule_id
                index_rule_id
                set_id
                weight
                min
                max
            }
        }
    }
`;

export const deleteColumnL = /* GraphQL */ `
    mutation deleteColumnL($id: [Int!]) {
        deleteColumnL(ids: $id) {
            mutation
            decision_id
            # parent_id
            # owner
            # is_column
            # is_row
            factors {
                id
                decision_id
                parent_id
                # reference_id
                owner
                name
                json
                order_str
                is_group
                is_table
                is_column
                # is_row
                # is_bp
                format
                suffix
                value_type
                enum_id
                m_score
                m_index
                score_rule_id
                index_rule_id
                set_id
                weight
                min
                max
            }
        }
    }
`;

export const createChoiceL = /* GraphQL */ `
    mutation createChoiceL(
        $decision_id: Int!
        $name: String!
        $description: String
    ) {
        createChoiceL(
            input: {
                decision_id: $decision_id
                name: $name
                description: $description
            }
        ) {
            decision_id
            mutation
            choices {
                id
                name
                decision_id
                owner
                description
                created
                updated
            }
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
            values {
                choice_id
                factor_id
                row_id
                value
                enum_value_id
                json
            }
        }
    }
`;

export const deleteChoice = /* GraphQL */ `
    mutation deleteChoice($id: Int!) {
        deleteChoice(id: $id) {
            decision_id
            mutation
            choices {
                id
                name
                decision_id
                owner
                description
                created
                updated
            }
        }
    }
`;

export const updateChoice = /* GraphQL */ `
    mutation updateChoice($id: Int!, $name: String!, $description: String) {
        updateChoice(
            input: { id: $id, name: $name, description: $description }
        ) {
            decision_id
            mutation
            choices {
                id
                name
                decision_id
                owner
                description
                created
                updated
            }
        }
    }
`;

export const createValueL = /* GraphQL */ `
    mutation createValueL(
        $choice_id: Int!
        $factor_id: Int!
        $row_id: String!
        $value: String
        $enum_value_id: Int
        $json: String
    ) {
        createValueL(
            input: {
                choice_id: $choice_id
                factor_id: $factor_id
                row_id: $row_id
                value: $value
                enum_value_id: $enum_value_id
                json: $json
            }
        ) {
            decision_id
            mutation
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
            values {
                choice_id
                factor_id
                row_id
                value
                enum_value_id
                json
            }
        }
    }
`;

export const deleteValueL = /* GraphQL */ `
    mutation deleteValueL(
        $choice_id: Int!
        $factor_id: Int!
        $row_id: String!
    ) {
        deleteValueL(
            choice_id: $choice_id
            factor_id: $factor_id
            row_id: $row_id
        ) {
            decision_id
            mutation
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
            values {
                choice_id
                factor_id
                row_id
                value
                enum_value_id
                json
            }
        }
    }
`;

export const updateValueL = /* GraphQL */ `
    mutation updateValueL(
        $choice_id: Int!
        $factor_id: Int!
        $row_id: String!
        $value: String
        $enum_value_id: Int
        $json: String
    ) {
        updateValueL(
            input: {
                choice_id: $choice_id
                factor_id: $factor_id
                row_id: $row_id
                value: $value
                enum_value_id: $enum_value_id
                json: $json
            }
        ) {
            decision_id
            mutation
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
            values {
                choice_id
                factor_id
                row_id
                value
                enum_value_id
                json
                # mutation
            }
        }
    }
`;

export const createViewpointL = /* GraphQL */ `
    mutation createViewpointL($decision_id: Int!, $name: String!) {
        createViewpointL(input: { decision_id: $decision_id, name: $name }) {
            decision_id
            mutation
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
            viewpoint_mappings {
                viewpoint_id
                factor_id
                score_rule_id
                index_rule_id
                set_id
                json
                order_str
                unit_id
                weight
                use_m_score
                use_m_index
            }
            values {
                choice_id
                factor_id
                value
                enum_value_id
                json
                # mutation
            }
            viewpoints {
                id
                name
                decision_id
                owner
                updated
                created
            }
        }
    }
`;

export const updateViewpoint = /* GraphQL */ `
    mutation updateViewpoint($id: Int!, $name: String!, $description: String) {
        updateViewpoint(
            input: { id: $id, name: $name, description: $description }
        ) {
            decision_id
            mutation
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
            values {
                choice_id
                factor_id
                value
                enum_value_id
                json
                # mutation
            }
            viewpoints {
                id
                name
                decision_id
                description
                owner
                updated
                created
            }
        }
    }
`;

export const deleteViewpoint = /* GraphQL */ `
    mutation deleteViewpoint($id: Int!) {
        deleteViewpoint(id: $id) {
            decision_id
            mutation
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
            viewpoint_mappings {
                viewpoint_id
                factor_id
                score_rule_id
                index_rule_id
                set_id
                json
                order_str
                unit_id
                weight
                use_m_score
                use_m_index
            }
            values {
                choice_id
                factor_id
                value
                enum_value_id
                json
                # mutation
            }
            viewpoints {
                id
                name
                decision_id
                updated
                created
            }
        }
    }
`;

export const createScoreL = /* GraphQL */ `
    mutation createScoreL(
        $viewpoint_id: Int!
        $choice_id: Int!
        $factor_id: Int!
        $row_id: String!
        $m_score: Float
        $m_index: Int
        $order_str: String
    ) {
        createScoreL(
            input: {
                viewpoint_id: $viewpoint_id
                choice_id: $choice_id
                factor_id: $factor_id
                row_id: $row_id
                m_score: $m_score
                m_index: $m_index
                order_str: $order_str
            }
        ) {
            decision_id
            mutation
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
            }
        }
    }
`;

export const updateScoreL = /* GraphQL */ `
    mutation updateScoreL(
        $viewpoint_id: Int!
        $choice_id: Int!
        $factor_id: Int!
        $row_id: String!
        $m_score: Float
        $m_index: Int
        $order_str: String
    ) {
        updateScoreL(
            input: {
                viewpoint_id: $viewpoint_id
                choice_id: $choice_id
                factor_id: $factor_id
                row_id: $row_id
                m_score: $m_score
                m_index: $m_index
                order_str: $order_str
            }
        ) {
            decision_id
            mutation
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
            }
        }
    }
`;

export const deleteScoreL = /* GraphQL */ `
    mutation deleteScoreL(
        $viewpoint_id: Int!
        $choice_id: Int!
        $factor_id: Int!
    ) {
        deleteScoreL(
            viewpoint_id: $viewpoint_id
            choice_id: $choice_id
            factor_id: $factor_id
        ) {
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
        }
    }
`;

export const createScoreMap = /* GraphQL */ `
    mutation createScoreMap($decision_id: Int, $name: String!) {
        createScoreMap(input: { decision_id: $decision_id, name: $name }) {
            name
            decision_id
        }
    }
`;

//createScoreRuleRange createRuleRangeL
export const createRangeRuleL = /* GraphQL */ `
    mutation createRangeRuleL(
        $name: String!
        $null_value_score: Float!
        $min_range_score: Float!
        $decision_id: Int!
        $is_index_rule: Boolean!
        $interpolate_range: Boolean!
        $index_uses_score: Boolean!
        $min: Float
        $max: Float
    ) {
        createRangeRuleL(
            input: {
                name: $name
                decision_id: $decision_id
                is_index_rule: $is_index_rule
                index_uses_score: $index_uses_score
                interpolate_range: $interpolate_range
                null_value_score: $null_value_score
                min_range_score: $min_range_score
                min: $min
                max: $max
            }
        ) {
            decision_id
            mutation
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
            rules {
                id
                name
                decision_id
                null_value_score
                is_index_rule
                index_uses_score
                is_range
                interpolate_range
                is_match
                is_distance
                is_aggregate
                aggregate_function
                no_match_score
                min_range_score
                min
                max
                threshold
                ranges {
                    id
                    rule_id
                    at_least
                    score
                    label
                    description
                    bigger_than
                }
                matches {
                    rule_id
                    match
                    score
                    label
                    description
                }
            }
            rule_matches {
                rule_id
                match
                score
                label
                description
            }
            rule_ranges {
                id
                rule_id
                at_least
                score
                label
                description
            }
        }
    }
`;

export const updateRangeRuleL = /* GraphQL */ `
    mutation updateRangeRuleL(
        $name: String!
        $null_value_score: Float!
        $min_range_score: Float!
        $id: Int!
        $interpolate_range: Boolean!
        $min: Float
        $max: Float
    ) {
        updateRangeRuleL(
            input: {
                name: $name
                null_value_score: $null_value_score
                min_range_score: $min_range_score
                id: $id
                interpolate_range: $interpolate_range
                min: $min
                max: $max
            }
        ) {
            decision_id
            mutation
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
            rules {
                id
                name
                decision_id
                null_value_score
                is_index_rule
                index_uses_score
                is_range
                interpolate_range
                is_match
                is_distance
                is_aggregate
                aggregate_function
                no_match_score
                min_range_score
                min
                max
                threshold
                ranges {
                    id
                    rule_id
                    at_least
                    score
                    label
                    description
                    bigger_than
                }
                matches {
                    rule_id
                    match
                    score
                    label
                    description
                }
            }
            rule_matches {
                rule_id
                match
                score
                label
                description
            }
            rule_ranges {
                id
                rule_id
                at_least
                score
                label
                description
            }
        }
    }
`;

export const createMatchRuleL = /* GraphQL */ `
    mutation createMatchRuleL(
        $name: String!
        $null_value_score: Float!
        $no_match_score: Float!
        $decision_id: Int!
        $is_index_rule: Boolean!
    ) {
        createMatchRuleL(
            input: {
                name: $name
                null_value_score: $null_value_score
                no_match_score: $no_match_score
                decision_id: $decision_id
                is_index_rule: $is_index_rule
            }
        ) {
            decision_id
            mutation
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
            rules {
                id
                name
                decision_id
                null_value_score
                is_index_rule
                index_uses_score
                is_range
                interpolate_range
                is_match
                is_distance
                is_aggregate
                aggregate_function
                no_match_score
                min_range_score
                min
                max
                threshold
                ranges {
                    id
                    rule_id
                    at_least
                    score
                    label
                    description
                    bigger_than
                }
                matches {
                    rule_id
                    match
                    score
                    label
                    description
                }
            }
            rule_matches {
                rule_id
                match
                score
                label
                description
            }
            rule_ranges {
                id
                rule_id
                at_least
                score
                label
                description
            }
        }
    }
`;

export const updateMatchRuleL = /* GraphQL */ `
    mutation updateMatchRuleL(
        $id: Int!
        $name: String
        $null_value_score: Float
        $no_match_score: Float
        $min: Float
        $max: Float
    ) {
        updateMatchRuleL(
            input: {
                id: $id
                name: $name
                null_value_score: $null_value_score
                no_match_score: $no_match_score
                min: $min
                max: $max
            }
        ) {
            decision_id
            mutation
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
            rules {
                id
                name
                decision_id
                null_value_score
                is_index_rule
                index_uses_score
                is_range
                interpolate_range
                is_match
                is_distance
                is_aggregate
                aggregate_function
                no_match_score
                min_range_score
                min
                max
                threshold
                ranges {
                    id
                    rule_id
                    at_least
                    score
                    label
                    description
                    bigger_than
                }
                matches {
                    rule_id
                    match
                    score
                    label
                    description
                }
            }
            rule_matches {
                rule_id
                match
                score
                label
                description
            }
            rule_ranges {
                id
                rule_id
                at_least
                score
                label
                description
            }
        }
    }
`;

export const createAggregateRuleL = /* GraphQL */ `
    mutation createAggregateRuleL(
        $name: String!
        $null_value_score: Float!
        $aggregate_function: String!
        $decision_id: Int!
        $min: Float
        $max: Float
    ) {
        createAggregateRuleL(
            input: {
                name: $name
                null_value_score: $null_value_score
                aggregate_function: $aggregate_function
                decision_id: $decision_id
                min: $min
                max: $max
            }
        ) {
            decision_id
            mutation
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
            rules {
                id
                name
                decision_id
                null_value_score
                is_index_rule
                index_uses_score
                is_range
                interpolate_range
                is_match
                is_distance
                is_aggregate
                aggregate_function
                no_match_score
                min_range_score
                min
                max
                threshold
                ranges {
                    id
                    rule_id
                    at_least
                    score
                    label
                    description
                    bigger_than
                }
                matches {
                    rule_id
                    match
                    score
                    label
                    description
                }
            }
            rule_matches {
                rule_id
                match
                score
                label
                description
            }
            rule_ranges {
                id
                rule_id
                at_least
                score
                label
                description
            }
        }
    }
`;

export const updateAggregateRuleL = /* GraphQL */ `
    mutation updateAggregateRuleL(
        $id: Int!
        $name: String!
        $null_value_score: Float!
        $aggregate_function: String!
        $min: Float
        $max: Float
    ) {
        updateAggregateRuleL(
            input: {
                id: $id
                name: $name
                null_value_score: $null_value_score
                aggregate_function: $aggregate_function
                min: $min
                max: $max
            }
        ) {
            decision_id
            mutation
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
            rules {
                id
                name
                decision_id
                null_value_score
                is_index_rule
                index_uses_score
                is_range
                interpolate_range
                is_match
                is_distance
                is_aggregate
                aggregate_function
                no_match_score
                min_range_score
                min
                max
                threshold
                ranges {
                    id
                    rule_id
                    at_least
                    score
                    label
                    description
                    bigger_than
                }
                matches {
                    rule_id
                    match
                    score
                    label
                    description
                }
            }
            rule_matches {
                rule_id
                match
                score
                label
                description
            }
            rule_ranges {
                id
                rule_id
                at_least
                score
                label
                description
            }
        }
    }
`;

export const createRuleMatchL = /* GraphQL */ `
    mutation createRuleMatchL(
        $rule_id: Int!
        $match: String!
        $score: Float!
        $label: String!
    ) {
        createRuleMatchL(
            input: {
                rule_id: $rule_id
                match: $match
                score: $score
                label: $label
            }
        ) {
            decision_id
            mutation
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
            rules {
                id
                name
                decision_id
                null_value_score
                is_index_rule
                index_uses_score
                is_range
                interpolate_range
                is_match
                is_distance
                is_aggregate
                aggregate_function
                no_match_score
                min_range_score
                min
                max
                threshold
                ranges {
                    id
                    rule_id
                    at_least
                    score
                    label
                    description
                    bigger_than
                }
                matches {
                    rule_id
                    match
                    score
                    label
                    description
                }
            }
            rule_matches {
                rule_id
                match
                score
                label
                description
            }
            rule_ranges {
                id
                rule_id
                at_least
                score
                label
                description
            }
        }
    }
`;
//createScoreRange-> createRuleRangeL
export const createRuleRangeL = /* GraphQL */ `
    mutation createRuleRangeL(
        $rule_id: Int!
        $at_least: Float!
        $label: String!
        $score: Float!
        $bigger_than: Boolean!
    ) {
        createRuleRangeL(
            input: {
                rule_id: $rule_id
                at_least: $at_least
                score: $score
                label: $label
                bigger_than: $bigger_than
            }
        ) {
            decision_id
            mutation
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
            rules {
                id
                name
                decision_id
                null_value_score
                is_index_rule
                index_uses_score
                is_range
                interpolate_range
                is_match
                is_distance
                is_aggregate
                aggregate_function
                no_match_score
                min_range_score
                min
                max
                threshold
                ranges {
                    id
                    rule_id
                    at_least
                    score
                    label
                    description
                    bigger_than
                }
                matches {
                    rule_id
                    match
                    score
                    label
                    description
                }
            }
            rule_matches {
                rule_id
                match
                score
                label
                description
            }
            rule_ranges {
                id
                rule_id
                at_least
                score
                label
                description
            }
        }
    }
`;

export const updateRuleRangeL = /* GraphQL */ `
    mutation updateRuleRangeL(
        $id: Int!
        $at_least: Float!
        $label: String!
        $score: Float!
    ) {
        updateRuleRangeL(
            input: {
                id: $id
                at_least: $at_least
                score: $score
                label: $label
            }
        ) {
            decision_id
            mutation
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
            rules {
                id
                name
                decision_id
                null_value_score
                is_index_rule
                index_uses_score
                is_range
                interpolate_range
                is_match
                is_distance
                is_aggregate
                aggregate_function
                no_match_score
                min_range_score
                min
                max
                threshold
                ranges {
                    id
                    rule_id
                    at_least
                    score
                    label
                    description
                    bigger_than
                }
                matches {
                    rule_id
                    match
                    score
                    label
                    description
                }
            }
            rule_matches {
                rule_id
                match
                score
                label
                description
            }
            rule_ranges {
                id
                rule_id
                at_least
                score
                label
                description
            }
        }
    }
`;

export const deleteRuleL = /* GraphQL */ `
    mutation deleteRuleL($id: Int!) {
        deleteRuleL(id: $id) {
            decision_id
            mutation
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
            rules {
                id
                name
                decision_id
                null_value_score
                is_index_rule
                index_uses_score
                is_range
                interpolate_range
                is_match
                is_distance
                is_aggregate
                aggregate_function
                no_match_score
                min_range_score
                min
                max
                threshold
                ranges {
                    id
                    rule_id
                    at_least
                    score
                    label
                    description
                }
                matches {
                    rule_id
                    match
                    score
                    label
                    description
                }
            }
            viewpoint_mappings {
                viewpoint_id
                factor_id
                score_rule_id
                index_rule_id
                set_id
                json
                order_str
                unit_id
                weight
                use_m_score
                use_m_index
            }
            values {
                choice_id
                factor_id
                value
                enum_value_id
                json
            }
            factors {
                id
                decision_id
                parent_id
                owner
                name
                json
                order_str
                is_group
                is_table
                is_column
                format
                suffix
                value_type
                enum_id
                m_score
                m_index
                score_rule_id
                index_rule_id
                set_id
                weight
            }
            rule_matches {
                rule_id
                match
                score
                label
                description
            }
            rule_ranges {
                id
                rule_id
                bigger_than
                at_least
                score
                label
                description
            }
        }
    }
`;

export const updateRuleMatchL = /* GraphQL */ `
    mutation updateRuleMatchL(
        $rule_id: Int!
        $match: String!
        $score: Float!
        $label: String
    ) {
        updateRuleMatchL(
            input: {
                rule_id: $rule_id
                match: $match
                score: $score
                label: $label
            }
        ) {
            decision_id
            mutation
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
            rules {
                id
                name
                decision_id
                null_value_score
                is_index_rule
                index_uses_score
                is_range
                interpolate_range
                is_match
                is_distance
                is_aggregate
                aggregate_function
                no_match_score
                min_range_score
                min
                max
                threshold
                ranges {
                    id
                    rule_id
                    at_least
                    score
                    label
                    description
                    bigger_than
                }
                matches {
                    rule_id
                    match
                    score
                    label
                    description
                }
            }
            rule_matches {
                rule_id
                match
                score
                label
                description
            }
            rule_ranges {
                id
                rule_id
                at_least
                score
                label
                description
            }
        }
    }
`;

export const deleteRuleMatchL = /* GraphQL */ `
    mutation deleteRuleMatchL($rule_id: Int!, $match: String!) {
        deleteRuleMatchL(rule_id: $rule_id, match: $match) {
            decision_id
            mutation
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
            rules {
                id
                name
                decision_id
                null_value_score
                is_index_rule
                index_uses_score
                is_range
                interpolate_range
                is_match
                is_distance
                is_aggregate
                aggregate_function
                no_match_score
                min_range_score
                min
                max
                threshold
                ranges {
                    id
                    rule_id
                    at_least
                    score
                    label
                    description
                }
                matches {
                    rule_id
                    match
                    score
                    label
                    description
                }
            }
            viewpoint_mappings {
                viewpoint_id
                factor_id
                score_rule_id
                index_rule_id
                set_id
                json
                order_str
                unit_id
                weight
                use_m_score
                use_m_index
            }
            values {
                choice_id
                factor_id
                value
                enum_value_id
                json
            }
            factors {
                id
                decision_id
                parent_id
                owner
                name
                json
                order_str
                is_group
                is_table
                is_column
                format
                suffix
                value_type
                enum_id
                m_score
                m_index
                score_rule_id
                index_rule_id
                set_id
                weight
            }
            rule_matches {
                rule_id
                match
                score
                label
                description
            }
            rule_ranges {
                id
                rule_id
                at_least
                score
                label
                description
            }
            viewpoints {
                id
                name
                decision_id
            }
        }
    }
`;

export const deleteRuleRangeL = /* GraphQL */ `
    mutation deleteRuleRangeL($id: Int!) {
        deleteRuleRangeL(id: $id) {
            decision_id
            mutation
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
            rules {
                id
                name
                decision_id
                null_value_score
                is_index_rule
                index_uses_score
                is_range
                interpolate_range
                is_match
                is_distance
                is_aggregate
                aggregate_function
                no_match_score
                min_range_score
                min
                max
                threshold
                ranges {
                    id
                    rule_id
                    at_least
                    score
                    label
                    description
                }
                matches {
                    rule_id
                    match
                    score
                    label
                    description
                }
            }
            rule_matches {
                rule_id
                match
                score
                label
                description
            }
            rule_ranges {
                id
                rule_id
                at_least
                score
                label
                bigger_than
                description
            }
        }
    }
`;

export const updateScoringL = /* GraphQL */ `
    mutation updateScoringL(
        $rrc: [RuleRangeCreateInputL!]
        $rru: [RuleRangeUpdateInputL!]
        $rrd: [Int!]
        $rmc: [RuleMatchCreateInputL!]
        $rmu: [RuleMatchUpdateInputL!]
        $rmd: [RuleMatchIDInputL!]
        $cmc: [ClassMappingInput!]
        $cmu: [ClassMappingInput!]
        $cmd: [ClassMappingIdInput!]!
    ) {
        updateScoringL(
            rrc: $rrc
            rru: $rru
            rrd: $rrd
            rmc: $rmc
            rmu: $rmu
            rmd: $rmd
            cmc: $cmc
            cmu: $cmu
            cmd: $cmd
        ) {
            decision_id
            mutation
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
            rules {
                id
                name
                decision_id
                null_value_score
                is_index_rule
                index_uses_score
                is_range
                interpolate_range
                is_match
                is_distance
                is_aggregate
                aggregate_function
                no_match_score
                min_range_score
                min
                max
                threshold
                ranges {
                    id
                    rule_id
                    at_least
                    score
                    label
                    description
                }
                matches {
                    rule_id
                    match
                    score
                    label
                    description
                }
            }
            rule_matches {
                rule_id
                match
                score
                label
                description
            }
            rule_ranges {
                id
                rule_id
                at_least
                score
                label
                bigger_than
                description
            }
        }
    }
`;

export const cRangeRuleL = /* GraphQL */ `
    mutation cRangeRuleL(
        $vpm_ids: [ViewpointMappingID!]
        $f_ids: [Int!]
        $rule: RangeRuleCreateInputL!
        $ranges: [ComboRuleRangeCreateInputL!]
    ) {
        cRangeRuleL(
            vpm_ids: $vpm_ids
            f_ids: $f_ids
            rule: $rule
            ranges: $ranges
        ) {
            decision_id
            mutation
            viewpoint_mappings {
                viewpoint_id
                factor_id
                score_rule_id
                set_id
                index_rule_id
                json
                unit_id
                order_str
                weight
                use_m_score
                use_m_index
            }
            rules {
                id
                name
                decision_id
                null_value_score
                is_index_rule
                index_uses_score
                is_range
                interpolate_range
                is_match
                is_distance
                is_aggregate
                aggregate_function
                no_match_score
                min_range_score
                min
                max
                threshold
                matches {
                    rule_id
                    match
                    score
                    label
                    description
                }
                ranges {
                    id
                    rule_id
                    at_least
                    score
                    label
                    bigger_than
                    description
                }
            }
            rule_matches {
                rule_id
                match
                score
                label
                description
            }
            rule_ranges {
                id
                rule_id
                at_least
                score
                label
                bigger_than
                description
            }
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
        }
    }
`;

export const cMatchRuleL = /* GraphQL */ `
    mutation cMatchRuleL(
        $vpm_ids: [ViewpointMappingID!]
        $f_ids: [Int!]
        $rule: MatchRuleCreateInputL!
        $matches: [ComboRuleMatchCreateInputL!]
    ) {
        cMatchRuleL(
            vpm_ids: $vpm_ids
            f_ids: $f_ids
            rule: $rule
            matches: $matches
        ) {
            decision_id
            mutation
            viewpoint_mappings {
                viewpoint_id
                factor_id
                score_rule_id
                set_id
                index_rule_id
                json
                unit_id
                order_str
                weight
                use_m_score
                use_m_index
            }
            rules {
                id
                name
                decision_id
                null_value_score
                is_index_rule
                index_uses_score
                is_range
                interpolate_range
                is_match
                is_distance
                is_aggregate
                aggregate_function
                no_match_score
                min_range_score
                min
                max
                threshold
                matches {
                    rule_id
                    match
                    score
                    label
                    description
                }
                ranges {
                    id
                    rule_id
                    at_least
                    score
                    label
                    bigger_than
                    description
                }
            }
            rule_matches {
                rule_id
                match
                score
                label
                description
            }
            rule_ranges {
                id
                rule_id
                at_least
                score
                label
                bigger_than
                description
            }
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
        }
    }
`;

export const cAggRuleL = /* GraphQL */ `
    mutation cAggRuleL(
        $vpm_ids: [ViewpointMappingID!]
        $f_ids: [Int!]
        $rule: AggregateRuleCreateInputL!
    ) {
        cAggRuleL(vpm_ids: $vpm_ids, f_ids: $f_ids, rule: $rule) {
            decision_id
            mutation
            viewpoint_mappings {
                viewpoint_id
                factor_id
                score_rule_id
                set_id
                index_rule_id
                json
                unit_id
                order_str
                weight
                use_m_score
                use_m_index
            }
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
            rules {
                id
                name
                decision_id
                null_value_score
                is_index_rule
                index_uses_score
                is_range
                interpolate_range
                is_match
                is_distance
                is_aggregate
                aggregate_function
                no_match_score
                min_range_score
                min
                max
                threshold
                matches {
                    rule_id
                    match
                    score
                    label
                    description
                }
                ranges {
                    id
                    rule_id
                    at_least
                    score
                    label
                    bigger_than
                    description
                }
            }
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
        }
    }
`;

export const cSetL = /* GraphQL */ `
    mutation cSetL(
        $vpm_ids: [ViewpointMappingID!]
        $f_ids: [Int!]
        $set: ClassSetCreateInput!
        $maps: [ComboClassMappingInput!]
    ) {
        cSetL(vpm_ids: $vpm_ids, f_ids: $f_ids, set: $set, maps: $maps) {
            decision_id
            mutation
            viewpoint_mappings {
                viewpoint_id
                factor_id
                score_rule_id
                set_id
                index_rule_id
                json
                unit_id
                order_str
                weight
                use_m_score
                use_m_index
            }
            rules {
                id
                name
                decision_id
                null_value_score
                is_index_rule
                index_uses_score
                is_range
                interpolate_range
                is_match
                is_distance
                is_aggregate
                aggregate_function
                no_match_score
                min_range_score
                min
                max
                threshold
                matches {
                    rule_id
                    match
                    score
                    label
                    description
                }
            }
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
        }
    }
`;

export const createViewpointMappingL = /* GraphQL */ `
    mutation createViewpointMappingL(
        $viewpoint_id: Int!
        $factor_id: Int!
        $score_rule_id: Int
        $set_id: Int
        $json: String
        $order_str: String
        $index_rule_id: Int
        $unit_id: Int
        $weight: Float
        $use_m_score: Boolean!
        $use_m_index: Boolean!
    ) {
        createViewpointMappingL(
            input: {
                viewpoint_id: $viewpoint_id
                factor_id: $factor_id
                score_rule_id: $score_rule_id
                set_id: $set_id
                json: $json
                order_str: $order_str
                index_rule_id: $index_rule_id
                unit_id: $unit_id
                weight: $weight
                use_m_score: $use_m_score
                use_m_index: $use_m_index
            }
        ) {
            decision_id
            mutation
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
            viewpoint_mappings {
                viewpoint_id
                factor_id
                score_rule_id
                set_id
                index_rule_id
                json
                unit_id
                order_str
                weight
                use_m_score
                use_m_index
            }
        }
    }
`;

export const updateViewpointMappingL = /* GraphQL */ `
    mutation updateViewpointMappingL(
        $viewpoint_id: Int!
        $factor_id: Int!
        $score_rule_id: Int
        $set_id: Int
        $json: String
        $order_str: String
        $index_rule_id: Int
        $unit_id: Int
        $weight: Float
        $use_m_score: Boolean
        $use_m_index: Boolean
    ) {
        updateViewpointMappingL(
            input: {
                viewpoint_id: $viewpoint_id
                factor_id: $factor_id
                score_rule_id: $score_rule_id
                set_id: $set_id
                json: $json
                order_str: $order_str
                index_rule_id: $index_rule_id
                unit_id: $unit_id
                weight: $weight
                use_m_score: $use_m_score
                use_m_index: $use_m_index
            }
        ) {
            decision_id
            mutation
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
            viewpoint_mappings {
                viewpoint_id
                factor_id
                score_rule_id
                index_rule_id
                set_id
                json
                order_str
                unit_id
                weight
                use_m_score
                use_m_index
            }
        }
    }
`;

export const batchUpdateViewpointMappingL = /* GraphQL */ `
    mutation batchUpdateViewpointMappingL(
        $input: [ViewpointMappingUpdateInputL!]!
    ) {
        batchUpdateViewpointMappingL(input: $input) {
            decision_id
            mutation
            viewpoint_mappings {
                viewpoint_id
                factor_id
                score_rule_id
                index_rule_id
                set_id
                json
                order_str
                unit_id
                weight
                use_m_score
                use_m_index
            }
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
        }
    }
`;

export const batchUpdateViewpointWeightL = /* GraphQL */ `
    mutation batchUpdateViewpointMappingL(
        $input: [ViewpointMappingUpdateInputL!]!
    ) {
        batchUpdateViewpointMappingL(input: $input) {
            decision_id
            mutation
            viewpoint_mappings {
                viewpoint_id
                factor_id
                score_rule_id
                index_rule_id
                set_id
                json
                order_str
                unit_id
                weight
                use_m_score
                use_m_index
            }
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
        }
    }
`;

export const batchUpdateValuePresentation = /* GraphQL */ `
    mutation batchUpdateViewpointMappingL(
        $input: [ViewpointMappingUpdateInputL!]!
    ) {
        batchUpdateViewpointMappingL(input: $input) {
            decision_id
            mutation
            viewpoint_mappings {
                viewpoint_id
                factor_id
                score_rule_id
                index_rule_id
                set_id
                json
                order_str
                unit_id
                weight
                use_m_score
                use_m_index
            }
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
        }
    }
`;

export const deleteViewpointMapping = /* GraphQL */ `
    mutation deleteViewpointMapping($viewpoint_id: Int!, $factor_id: Int!) {
        deleteViewpointMapping(
            viewpoint_id: $viewpoint_id
            factor_id: $factor_id
        ) {
            decision_id
            mutation
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
            viewpoint_mappings {
                viewpoint_id
                factor_id
                score_rule_id
                set_id
                index_rule_id
                json
                unit_id
                order_str
                weight
            }
        }
    }
`;

export const createClassSet = /* GraphQL */ `
    mutation createClassSet(
        $workspace_id: Int!
        $name: String!
        $json: String
    ) {
        createClassSet(
            input: { workspace_id: $workspace_id, name: $name, json: $json }
        ) {
            workspace_id
            mutation
            class_sets {
                id
                workspace_id
                name
                mappings {
                    set_id
                    class_id
                    index
                    score
                    class {
                        id
                        workspace_id
                        name
                        json
                    }
                    json
                    fail
                }
                json
            }
        }
    }
`;

export const updateClassSet = /* GraphQL */ `
    mutation updateClassSet($set_id: Int!, $name: String!, $json: String) {
        updateClassSet(set_id: $set_id, input: { name: $name, json: $json }) {
            workspace_id
            mutation
            class_sets {
                id
                workspace_id
                name
                mappings {
                    set_id
                    class_id
                    index
                    score
                    class {
                        id
                        workspace_id
                        name
                        json
                    }
                    json
                    fail
                }
                json
            }
        }
    }
`;

export const deleteClassSet = /* GraphQL */ `
    mutation deleteClassSet($set_id: Int!) {
        deleteClassSet(set_id: $set_id) {
            workspace_id
            mutation
            class_sets {
                id
                workspace_id
                name
                mappings {
                    set_id
                    class_id
                    index
                    score
                    class {
                        id
                        workspace_id
                        name
                        json
                    }
                    json
                    fail
                }
                json
            }
        }
    }
`;

export const createClassMapping = /* GraphQL */ `
    mutation createClassMapping(
        $set_id: Int!
        $class_id: Int!
        $index: Int!
        $fail: Boolean!
        $json: String
        $score: Float
    ) {
        createClassMapping(
            input: {
                set_id: $set_id
                class_id: $class_id
                index: $index
                json: $json
                fail: $fail
                score: $score
            }
        ) {
            workspace_id
            mutation
            class_mappings {
                set_id
                class_id
                index
                score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                json
                fail
            }
        }
    }
`;

export const updateClassMapping = /* GraphQL */ `
    mutation updateClassMapping(
        $set_id: Int!
        $class_id: Int!
        $index: Int!
        $json: String
        $fail: Boolean!
    ) {
        updateClassMapping(
            set_id: $set_id
            class_id: $class_id
            input: { index: $index, json: $json, fail: $fail }
        ) {
            workspace_id
            mutation
            class_mappings {
                set_id
                class_id
                index
                score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                json
                fail
            }
        }
    }
`;

export const deleteClassMapping = /* GraphQL */ `
    mutation deleteClassMapping($set_id: Int!, $class_id: Int!) {
        deleteClassMapping(set_id: $set_id, class_id: $class_id) {
            workspace_id
            mutation
            class_mappings {
                set_id
                class_id
                index
                score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                json
                fail
            }
        }
    }
`;

export const createClass = /* GraphQL */ `
    mutation createClass(
        $workspace_id: Int!
        $name: String!
        $description: String!
        $json: String
    ) {
        createClass(
            input: {
                workspace_id: $workspace_id
                name: $name
                description: $description
                json: $json
            }
        ) {
            workspace_id
            mutation
            classes {
                id
                workspace_id
                name
                description
                json
            }
        }
    }
`;

export const updateClass = /* GraphQL */ `
    mutation updateClass(
        $name: String!
        $description: String!
        $class_id: Int!
        $json: String
    ) {
        updateClass(
            class_id: $class_id
            input: { name: $name, description: $description, json: $json }
        ) {
            workspace_id
            mutation
            classes {
                id
                workspace_id
                name
                description
                json
            }
        }
    }
`;

export const deleteClass = /* GraphQL */ `
    mutation deleteClass($class_id: Int!) {
        deleteClass(class_id: $class_id) {
            workspace_id
            mutation
            classes {
                id
                workspace_id
                name
                json
            }
        }
    }
`;

export const createUnit = /* GraphQL */ `
    mutation createUnit($name: String!, $abrv: String!) {
        createUnit(input: { name: $name, abrv: $abrv }) {
            id
            name
            abrv
        }
    }
`;

export const createUnitDomain = /* GraphQL */ `
    mutation createUnitDomain($unit_id: Int!, $domain_id: Int!) {
        createUnitDomain(input: { unit_id: $unit_id, domain_id: $domain_id }) {
            unit_id
            domain_id
        }
    }
`;

export const updateUnit = /* GraphQL */ `
    mutation updateUnit($unit_id: Int!, $name: String!, $abrv: String!) {
        updateUnit(unit_id: $unit_id, input: { name: $name, abrv: $abrv }) {
            id
            name
            abrv
        }
    }
`;

export const deleteUnit = /* GraphQL */ `
    mutation deleteUnit($unit_id: Int!) {
        deleteUnit(unit_id: $unit_id) {
            id
            name
            abrv
        }
    }
`;

export const createUnitConv = /* GraphQL */ `
    mutation createUnitConv(
        $from_id: Int!
        $to_id: Int!
        $multiplier: String!
    ) {
        createUnitConv(
            input: { from_id: $from_id, to_id: $to_id, multiplier: $multiplier }
        ) {
            from_id
            to_id
            multiplier
        }
    }
`;

export const updateUnitConv = /* GraphQL */ `
    mutation updateUnitConv(
        $multiplier: String!
        $from_id: Int!
        $to_id: Int!
    ) {
        updateUnitConv(
            from_id: $from_id
            to_id: $to_id
            input: { multiplier: $multiplier }
        ) {
            from_id
            to_id
            multiplier
        }
    }
`;

export const deleteUnitConv = /* GraphQL */ `
    mutation deleteUnitConv($from_id: Int!, $to_id: Int!) {
        deleteUnitConv(from_id: $from_id, to_id: $to_id) {
            from_id
            to_id
            multiplier
        }
    }
`;

export const createDomain = /* GraphQL */ `
    mutation createDomain(
        $name: String!,
        description: String,
        parent_id: Int
    ) {
        createDomain(input: { name: $name, description: $description, parent_id: $parent_id }) {
            id
            name
            description
            parent_id
        }
    }
`;

export const createEnums = /* GraphQL */ `
    mutation createEnums($decision_id: Int!, $names: [String!]) {
        createEnums(decision_id: $decision_id, names: $names) {
            id
            decision_id
            name
            values {
                id
                enum_id
                value
            }
        }
    }
`;

export const updateEnum = /* GraphQL */ `
    mutation updateEnum($name: String!, $enum_id: Int!) {
        updateEnum(enum_id: $enum_id, input: { name: $name }) {
            id
            decision_id
            name
            values {
                id
                enum_id
                value
            }
        }
    }
`;

export const deleteEnum = /* GraphQL */ `
    mutation deleteEnum($enum_id: Int!) {
        deleteEnum(enum_id: $enum_id) {
            id
            decision_id
            name
        }
    }
`;

export const createEnumValues = /* GraphQL */ `
    mutation createEnumValues($enum_id: Int!, $values: [String!]) {
        createEnumValues(enum_id: $enum_id, values: $values) {
            id
            enum_id
            value
        }
    }
`;

export const updateEnumValue = /* GraphQL */ `
    mutation updateEnumValue($enum_value_id: Int!, $value: String!) {
        updateEnumValue(
            enum_value_id: $enum_value_id
            input: { value: $value }
        ) {
            id
            enum_id
            value
        }
    }
`;

export const deleteEnumValues = /* GraphQL */ `
    mutation deleteEnumValues($enum_id: Int!, $enum_value_ids: [Int!]) {
        deleteEnumValues(enum_id: $enum_id, enum_value_ids: $enum_value_ids) {
            id
            enum_id
            value
        }
    }
`;

export const createApp = /* GraphQL */ `
    mutation createApp(
        $decision_id: Int!
        $title: String!
        $description: String
        $header: String
        $json: String
        $image: String
    ) {
        createApp(
            input: {
                decision_id: $decision_id
                title: $title
                description: $description
                header: $header
                json: $json
                image: $image
            }
        ) {
            decision_id
            mutation
            apps {
                id
                decision_id
                title
                description
                header
                json
                image
                owner
                created
                updated
            }
        }
    }
`;

export const updateApp = /* GraphQL */ `
    mutation updateApp(
        $id: Int!
        $title: String!
        $description: String
        $header: String
        $json: String
        $image: String
    ) {
        updateApp(
            input: {
                id: $id
                title: $title
                description: $description
                header: $header
                json: $json
                image: $image
            }
        ) {
            decision_id
            mutation
            apps {
                id
                decision_id
                title
                description
                header
                json
                image
                created
                updated
                owner
                tabs {
                    id
                    decision_id
                    title
                    json
                    edit_flags
                    display_flags
                    filter_type
                    row_type
                    column_type
                    default_filter_id
                    type
                    description
                    order_str
                }
            }
        }
    }
`;

export const copyApp = /* GraphQL */ `
    mutation copyApp($app_id: Int!, $dest_name: String!, $dest_d_id: Int!) {
        copyApp(app_id: $app_id, dest_name: $dest_name, dest_d_id: $dest_d_id) {
            decision_id
            apps {
                id
                title
            }
            tabs {
                id
            }
            app_tabs {
                app_id
                tab_id
            }
            mutation
        }
    }
`;

export const deleteApp = /* GraphQL */ `
    mutation deleteApp($id: Int!) {
        deleteApp(id: $id) {
            decision_id
            mutation
            apps {
                id
                decision_id
                title
                description
                header
                json
                image
                owner
                created
                updated
                tabs {
                    id
                    decision_id
                    title
                    json
                    edit_flags
                    display_flags
                    filter_type
                    row_type
                    column_type
                    default_filter_id
                    type
                    description
                    order_str
                }
            }
        }
    }
`;

export const createTab = /* GraphQL */ `
    mutation createTab(
        $decision_id: Int!
        $title: String!
        $json: String
        $edit_flags: String
        $display_flags: String
        $filter_type: String!
        $row_type: String!
        $column_type: String!
        $default_filter_id: Int
        $type: String!
        $description: String
    ) {
        createTab(
            input: {
                decision_id: $decision_id
                title: $title
                json: $json
                edit_flags: $edit_flags
                display_flags: $display_flags
                filter_type: $filter_type
                row_type: $row_type
                column_type: $column_type
                default_filter_id: $default_filter_id
                type: $type
                description: $description
            }
        ) {
            decision_id
            mutation
            tabs {
                id
                decision_id
                title
                json
                edit_flags
                display_flags
                filter_type
                row_type
                column_type
                default_filter_id
                type
                description
            }
        }
    }
`;

export const updateTab = /* GraphQL */ `
    mutation updateTab(
        $id: Int!
        $title: String!
        $json: String
        $edit_flags: String
        $display_flags: String
        $filter_type: String!
        $row_type: String!
        $column_type: String!
        $default_filter_id: Int
        $type: String!
        $description: String
    ) {
        updateTab(
            input: {
                id: $id
                title: $title
                json: $json
                edit_flags: $edit_flags
                display_flags: $display_flags
                filter_type: $filter_type
                row_type: $row_type
                column_type: $column_type
                default_filter_id: $default_filter_id
                type: $type
                description: $description
            }
        ) {
            decision_id
            mutation
            tabs {
                id
                decision_id
                title
                json
                edit_flags
                display_flags
                filter_type
                row_type
                column_type
                default_filter_id
                type
                description
            }
        }
    }
`;

export const deleteTab = /* GraphQL */ `
    mutation deleteTab($id: Int!) {
        deleteTab(id: $id) {
            decision_id
            mutation
            tabs {
                id
                decision_id
                title
                json
                edit_flags
                display_flags
                filter_type
                row_type
                column_type
                default_filter_id
                type
                description
            }
            app_tabs {
                app_id
                tab_id
            }
        }
    }
`;

export const createAppTab = /* GraphQL */ `
    mutation createAppTab($app_id: Int!, $tab_id: Int!, $order_str: String) {
        createAppTab(
            input: { app_id: $app_id, tab_id: $tab_id, order_str: $order_str }
        ) {
            decision_id
            mutation
            app_tabs {
                app_id
                tab_id
                order_str
            }
        }
    }
`;

export const updateAppTab = /* GraphQL */ `
    mutation updateAppTab($app_id: Int!, $tab_id: Int!, $order_str: String!) {
        updateAppTab(
            input: { app_id: $app_id, tab_id: $tab_id, order_str: $order_str }
        ) {
            decision_id
            mutation
            app_tabs {
                app_id
                tab_id
                order_str
            }
        }
    }
`;

export const deleteAppTab = /* GraphQL */ `
    mutation deleteAppTab($app_id: Int!, $tab_id: Int!) {
        deleteAppTab(input: { app_id: $app_id, tab_id: $tab_id }) {
            decision_id
            mutation
            app_tabs {
                app_id
                tab_id
                order_str
            }
        }
    }
`;

export const createTabFactor = /* GraphQL */ `
    mutation createTabFactor($input: [TabFactorInput!]) {
        createTabFactor(input: $input) {
            decision_id
            mutation
            tabs {
                id
                decision_id
                title
                json
                edit_flags
                display_flags
            }
            tab_factors {
                tab_id
                factor_id
            }
        }
    }
`;

export const deleteTabFactor = /* GraphQL */ `
    mutation deleteTabFactor($tab_id: Int!, $factor_ids: [Int!]) {
        deleteTabFactor(tab_id: $tab_id, factor_ids: $factor_ids) {
            decision_id
            mutation
            tabs {
                id
                decision_id
                title
                json
                edit_flags
                display_flags
            }
            tab_factors {
                tab_id
                factor_id
            }
        }
    }
`;

export const createTabChoice = /* GraphQL */ `
    mutation createTabChoice($input: [TabChoiceInput!]) {
        createTabChoice(input: $input) {
            decision_id
            mutation
            tabs {
                id
                decision_id
                title
                json
                edit_flags
                display_flags
            }
            tab_choices {
                tab_id
                choice_id
            }
        }
    }
`;

export const deleteTabChoice = /* GraphQL */ `
    mutation deleteTabChoice($tab_id: Int!, $choice_ids: [Int!]) {
        deleteTabChoice(tab_id: $tab_id, choice_ids: $choice_ids) {
            decision_id
            mutation
            tabs {
                id
                decision_id
                title
                json
                edit_flags
                display_flags
            }
            tab_choices {
                tab_id
                choice_id
            }
        }
    }
`;

export const createTabViewpoint = /* GraphQL */ `
    mutation createTabViewpoint($input: [TabViewpointInput!]) {
        createTabViewpoint(input: $input) {
            decision_id
            mutation
            tabs {
                id
                decision_id
                title
                json
                edit_flags
                display_flags
            }
            tab_viewpoints {
                tab_id
                viewpoint_id
            }
        }
    }
`;

export const deleteTabViewpoint = /* GraphQL */ `
    mutation deleteTabViewpoint($tab_id: Int!, $viewpoint_ids: [Int!]) {
        deleteTabViewpoint(tab_id: $tab_id, viewpoint_ids: $viewpoint_ids) {
            decision_id
            mutation
            tabs {
                id
                decision_id
                title
                json
                edit_flags
                display_flags
            }
            tab_viewpoints {
                tab_id
                viewpoint_id
            }
        }
    }
`;

export const portV2ToV3 = /* GraphQL */ `
    mutation portV2ToV3(
        $v2_decision_id: String!
        $workspace_id: Int!
        $stage: String!
    ) {
        portV2ToV3(
            v2_decision_id: $v2_decision_id
            workspace_id: $workspace_id
            stage: $stage
        ) {
            id
            status
            timestamp
        }
    }
`;

export const copyChoice = /* GraphQL */ `
    mutation copyChoice($input: ChoiceCopyInput!) {
        copyChoice(input: $input) {
            decision_id
            mutation
            choices {
                id
                name
                decision_id
                description
                owner
                updated
                created
            }
        }
    }
`;

export const copyViewpoint = /* GraphQL */ `
    mutation copyViewpoint($input: ViewpointCopyInput!) {
        copyViewpoint(input: $input) {
            decision_id
            mutation
            viewpoints {
                id
                name
                decision_id
                updated
                created
                description
            }
        }
    }
`;

export const processPdf = /* GraphQL */ `
    mutation processPdf($pdf_id: String!, $first_page: Int, $last_page: Int) {
        processPdf(
            pdf_id: $pdf_id
            first_page: $first_page
            last_page: $last_page
        ) {
            id
            status
            timestamp
        }
    }
`;

export const createCompany = /* GraphQL */ `
    mutation createCompany($input: CompanyCreateInput) {
        createCompany(input: $input) {
            id
            name
            json
        }
    }
`;

export const copyDecision = /* GraphQL */ `
    mutation copyDecision(
        $source_d_id: Int!
        $dest_name: String!
        $dest_ws_id: Int!
        $app_suffix: String!
        $dest_descr: String!
    ) {
        copyDecision(
            source_d_id: $source_d_id
            dest_name: $dest_name
            dest_ws_id: $dest_ws_id
            app_suffix: $app_suffix
            dest_descr: $dest_descr
        ) {
            mutation
        }
    }
`;

export const copyDecisionInto = /* GraphQL */ `
    mutation copyDecisionInto(
        $source_d_id: Int!
        $app_suffix: String!
        $dest_d_id: Int!
    ) {
        copyDecisionInto(
            source_d_id: $source_d_id
            app_suffix: $app_suffix
            dest_d_id: $dest_d_id
        ) {
            mutation
        }
    }
`;

export const reportErrorL = /* GraphQL */ `
    mutation reportErrorL(
        $e_type: String!
        $message: String!
        $mutation: Boolean!
        $field_name: String!
        $full_input: String!
    ) {
        reportErrorL(
            e_type: $e_type
            message: $message
            mutation: $mutation
            field_name: $field_name
            full_input: $full_input
        )
    }
`;

export const setInstanceAsDefault = /* GraphQL */ `
    mutation setInstanceAsDefault($viewpoint_id: Int!) {
        setInstanceAsDefault(viewpoint_id: $viewpoint_id)
    }
`;

export const getGeneratedSecondLevelFactors =
    /* GraphQL */
    `
        mutation getGeneratedSecondLevelFactors(
            $groups: [OpenAiFactor!]!
            $topic_area: String!
            $creativity: Float!
            $stage: String!
            $persona: String!
        ) {
            getGeneratedSecondLevelFactors(
                groups: $groups
                topic_area: $topic_area
                creativity: $creativity
                stage: $stage
                persona: $persona
            ) {
                id
                status
                timestamp
            }
        }
    `;

export const createThread =
    /* GraphQL */
    `
        mutation createThread($input: ThreadCreateInput!) {
            createThread(input: $input) {
                id
                factor_id
                decision_id
                choice_id
                row_id
                viewpoint_id
                author_id
                created
                name
                description
                resolved
            }
        }
    `;

export const deleteThread =
    /* GraphQL */
    `
        mutation deleteThread($thread_id: Int!) {
            deleteThread(thread_id: $thread_id) {
                id
                factor_id
                decision_id
                choice_id
                row_id
                viewpoint_id
                author_id
                created
                name
                description
                resolved
            }
        }
    `;

export const createComment =
    /* GraphQL */
    `
        mutation createComment($input: CommentCreateInput!) {
            createComment(input: $input) {
                id
                thread_id
                author_id
                created
                updated
                comment
                reply_to
                num_replies
            }
        }
    `;

export const resolveComment =
    /* GraphQL */
    `
        mutation resolveComment($id: Int!) {
            resolveComment(id: $id) {
                id
                thread_id
                author_id
                created
                updated
                comment
            }
        }
    `;

export const updateComment =
    /* GraphQL */
    `
        mutation updateComment($input: CommentUpdateInput!) {
            updateComment(input: $input) {
                id
                thread_id
                author_id
                created
                updated
                comment
                num_replies
                reply_to
            }
        }
    `;

export const deleteComment =
    /* GraphQL */
    `
        mutation deleteComment($comment_id: Int!) {
            deleteComment(comment_id: $comment_id) {
                id
                thread_id
                author_id
                created
                updated
                comment
                reply_to
                num_replies
            }
        }
    `;

export const deletePendingInvite =
    /* GraphQL */
    `
        mutation deletePendingInvite($email: String!) {
            deletePendingInvite(email: $email) {
                email
                workspace_id
                read
                write
                admin
            }
        }
    `;
