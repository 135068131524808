
import { Component, Prop, Vue, Watch, PropSync } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Decisions from "@/store/modules/Decisions";
import { Decision, Workspace, DecisionOptions } from "@/graphql/API";
import DialogCardTitle from "@/components/ui/DialogCardTitle.vue";
import Workspaces from "@/store/modules/Workspaces";
import FlashNotifications from "@/store/modules/FlashNotifications";
import RichTextEditor from "@/components/ui/RichTextEditor.vue";
import DecisionLabelInputs from "@/components/decisions/DecisionLabelInputs.vue";
import HTMLEditor from "@/components/ui/texteditor/HTMLEditor.vue";

const decisionsModule = getModule(Decisions);
const workspaceModule = getModule(Workspaces);
const flashNotificationsModule = getModule(FlashNotifications);
@Component({
    components: {
        DialogCardTitle,
        RichTextEditor,
        DecisionLabelInputs,
        HTMLEditor,
    },
})
export default class DecisionEditor extends Vue {
    @Prop({ default: false, type: Boolean })
    edit!: boolean;

    @Prop({ default: null, type: Number })
    decisionId!: number;

    @Prop()
    decision!: Decision;

    @Prop({ default: false, type: Boolean })
    isCopy!: boolean;

    @Prop({ default: false, type: Boolean })
    isPort!: boolean;

    private open = false;
    private decisionName = "";
    private formValid = false;
    private destWorkspaceId: number | null = null;
    private loading = false;
    private description = "";
    private v2DecisionId = "";

    private options: DecisionOptions = {};

    private blankOptions: DecisionOptions = {
        labels: {},
    };

    /*
    mounted(): void {
        this.onWatch();
    }
    */

    get title(): string {
        if (this.edit && this.decision) {
            if (this.isCopy) {
                return `Copy ${this.decision.name}`;
            } else {
                return "Edit Framework";
            }
        } else {
            return "Create Framework";
        }
    }

    get optionsString(): string {
        return JSON.stringify(this.options);
    }

    get workspaces(): Workspace[] {
        return workspaceModule.workspaceList;
    }

    get selectedWorkspaceId(): number | null {
        return workspaceModule.selectedWorkspaceId;
    }

    private async createDecision() {
        this.loading = true;
        let success = true;
        let msg = "";
        if (this.isCopy && this.decision && this.destWorkspaceId) {
            // appSuffix removed from decision name by Dave request
            // const tempDate = new Date();
            // const appSuffix = ` ${tempDate.getFullYear()}-${(
            //     "0" +
            //     (tempDate.getUTCMonth() + 1)
            // ).slice(-2)}-${("0" + tempDate.getUTCDate()).slice(-2)} ${
            //     ("00" + tempDate.getHours()).slice(-2) +
            //     ":" +
            //     ("00" + tempDate.getMinutes()).slice(-2)
            // }`;
            // // add date suffix to the inputed name
            // this.decisionName = this.decisionName + appSuffix;

            try {
                await decisionsModule.copyDecision({
                    source_d_id: this.decisionId,
                    dest_name: this.decisionName,
                    dest_ws_id: this.destWorkspaceId,
                    app_suffix: "",
                    description: this.description,
                });
                msg = `Framework name - ${this.decisionName}, was successfully copied`;
                if (this.destWorkspaceId === this.selectedWorkspaceId) {
                    await decisionsModule.fetchWorkspaceDecisions(
                        this.destWorkspaceId
                    );
                }
            } catch (e) {
                success = false;
                msg = `Something went wrong. Please refresh and try again`;
            }
        } else {
            if (this.edit && this.decision) {
                await decisionsModule.updateDecision({
                    decision_id: this.decision.id,
                    name: this.decisionName,
                    description: this.description,
                    json: this.optionsString,
                });
                msg = `Framework's name was successfully updated to - ${this.decisionName}`;
            } else {
                try {
                    await decisionsModule.createDecision({
                        name: this.decisionName,
                        workspace_id: getModule(Workspaces).selectedWorkspaceId,
                        description: this.description,
                        json: this.optionsString,
                    });

                    msg = `Framework name - ${this.decisionName}, was successfully created`;
                } catch (e) {
                    success = false;
                    msg = `Something went wrong. Please refresh and try again`;
                }
            }
        }
        this.loading = false;
        if (success) {
            flashNotificationsModule.success({
                message: msg,
                duration: 5000,
            });
        } else {
            flashNotificationsModule.error({
                message: msg,
                duration: 5000,
            });
        }
    }

    private updateLabel(e: { id: string; val: string }): void {
        if (this.options.labels) {
            Vue.set(this.options.labels, e.id, e.val);
        } else {
            Vue.set(this.options, "labels", {
                [e.id]: e.val,
            });
        }
    }

    @Watch("edit")
    onEditChange(): void {
        this.onDecisionChange();
    }

    @Watch("decision", { immediate: true, deep: true })
    async onDecisionChange(): Promise<void> {
        this.v2DecisionId = "";
        if (this.edit && this.decision != null) {
            this.decisionName = this.decision.name || "";
            if (this.isCopy) {
                this.destWorkspaceId = this.selectedWorkspaceId;
            }
            this.description = this.decision.description ?? "";
            this.options = this.decision.json
                ? JSON.parse(this.decision.json)
                : this.blankOptions;
        } else {
            this.decisionName = "";
            this.description = "";
            this.options = this.blankOptions;
        }
    }

    private updateTextDescription(val: string): void {
        this.description = val;
    }

    private async portDecision() {
        this.loading = true;
        await decisionsModule.portV2ToV3({
            v2_decision_id: this.v2DecisionId.trim(),
            workspace_id: workspaceModule.selectedWorkspaceId,
        });

        setTimeout(() => {
            this.fetchDecisions();
            this.loading = false;
        }, 2000);
    }

    private async fetchDecisions() {
        if (workspaceModule.selectedWorkspaceId) {
            decisionsModule.fetchWorkspaceDecisions(
                workspaceModule.selectedWorkspaceId
            );
        }
    }

    /*
    @Watch("open")
    private onWatch(): void {
        if (this.edit && this.decision != null) {
            this.decisionName = this.decision.name || "";
            if (this.isCopy) {
                this.destWorkspaceId = this.selectedWorkspaceId;
            }
        } else {
            this.decisionName = "";
        }
        if (!this.open) {
            this.syncedOpenModal = false;
        }
    }
    */
}
